import React from 'react';
import { Button } from "antd";
import { RightOutlined, LeftOutlined } from '@ant-design/icons';

import "./home.css"
import Logo from "./Logo";

const slideDesc = {
  1: { desc: "Define your extraction schema", img: "./schema.png" },
  2: { desc: "Upload your files", img: "./documents.png" },
  3: { desc: "Download the output", img: "./download.png" }
}
const Home = () => {
  const [currentSlide, setCurrentSlide] = React.useState(1)
  const secondaryHeaderSize = window.innerWidth > 580 ? '3.2rem' : '2.7rem'
  return (
    <main>
      <div className="hero">
        <div className="hero-1st-child">
          <div style={{ marginBottom: 80, display: 'flex', justifyContent: 'center' }}>
            <span className="text-gradient" style={{ fontSize: 20, fontWeight: 900 }}>Extract<Logo width={24} height={24} />Ninja</span>
          </div>
          <h1 style={{ fontSize: window.innerWidth > 580 ? '3.5rem' : '3.2rem' }}>
            Extract & analyze data from dozens of documents in minutes with an <span style={{ fontSize: window.innerWidth > 580 ? '3.3rem' : '3.2rem' }} className="text-gradient">AI-Powered</span> solution
          </h1>
          <div className="file-type-box-group" style={{ gap: window.innerWidth > 580 ? 80 : 130, position: 'relative', flexDirection: window.innerWidth > 580 ? 'row' : 'column' }}>
            <div className="file-type-box">
              <span className="file-type">CVs & resumes</span>
              <span className="file-type">Invoices, receipts, and POs</span>
              <span className="file-type">Contracts</span>
              <span className="file-type">Order confirmations</span>
              <span className="file-type">& many more..</span>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="300"
              viewBox="0 0 203.113 290.949"
              style={{ transform: window.innerWidth > 580 ? "rotate(306deg)" : "rotate(53deg)", position: 'absolute', top: window.innerWidth > 580 ? undefined : 230 }}
            >
              <g>
                <path fill="#F9E8C9" d="M108.521,195.245c-6.358-7.961-12.945-15.809-18.158-24.592-3.126-5.535-5.972-11.224-8.666-16.978C73.982,137.406,67.383,120.3,65.971,102.2A326.918,326.918,0,0,0,116.81,65.324a94.979,94.979,0,0,0,16.809-19.278c5.1-8.792,11.223-21.166,7.844-31.4-6.687-16.8-30.842-17.2-45.141-10.891a77.909,77.909,0,0,0-36.11,35.875C50.366,59.356,50,82.217,51.025,103.8,35.423,113.816,19.4,123.89,1.763,129.869A1.95,1.95,0,0,0,0,131.488c-.084,2.556,3.218,1.24,4.664.844a139.236,139.236,0,0,0,15.066-5.692A328.346,328.346,0,0,0,51.615,110.6c2.844,24.655,13.256,47.695,24.671,69.47,7.78,13.926,18.747,25.7,30.462,36.4a185.612,185.612,0,0,0,49.847,33.3C139.166,232.975,123.6,214.214,108.521,195.245ZM65.275,85.957c.073-16.761,1.932-34.236,11.278-48.6A64.656,64.656,0,0,1,97.536,17.63a31.08,31.08,0,0,1,24.168-2.5c5.041,1.618,9.11,5.585,7.527,11.221a59.557,59.557,0,0,1-4.473,12.771C114.139,59.051,84.114,81.04,65.412,94.136,65.308,91.412,65.252,88.688,65.275,85.957Z"/>
                <path fill="#F9E8C9" d="M202.86,280.177c-1.049-3.259-2.747-6.289-3.9-9.521-2.772-7.655-5.471-15.34-8.315-22.967a171.775,171.775,0,0,1-7.437-26.795c-2.262-11.393-4.9-22.864-6.54-34.392a1.46,1.46,0,0,0-.681-2.569c-3.75-.163-2.935,5.132-3.01,7.662.148,4.094.4,8.184.676,12.273.535,7.879,1.152,15.75,2.046,23.6,1.818,17.191,7.357,33.741,12.672,50.11-12.3-1.758-24.5-4.161-36.824-5.72-14.7-1.713-29.627-.705-44.336-1.3,14.537,6.186,30.24,8.644,45.344,13.031,11.968,3.363,24.15,6.456,36.594,7.293,4,.192,8.484.193,11.5-2.875A8.2,8.2,0,0,0,202.86,280.177Z"/>
              </g>
            </svg>
            <div className="file-type-box">
              <span className="file-type">Excel</span>
              <span className="file-type">CSV</span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: 40, maxWidth: 900, width: '100%', margin: 'auto', marginTop: 320 }}>
        <h1 style={{ color: "#003233", fontSize: secondaryHeaderSize }}>
          Eliminate tedious data entry in 3 easy steps.
        </h1>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: window.innerWidth > 580 ? 'row' : 'column'  }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 15, alignSelf: 'start' }}>
              <div
                style={{
                  border: '1px solid lightgrey',
                  width: '50px',
                  height: '50px',
                  borderRadius: '100%',
                  textAlign: 'center',
                  fontSize: '28px' 
                }}
              >
                {currentSlide}
              </div>
              {slideDesc[currentSlide].desc}
            </div>
            <div style={{ display: 'flex', gap: 5, alignSelf: 'end' }}>
              <Button icon={<LeftOutlined />} onClick={() => { currentSlide > 1 && setCurrentSlide(currentSlide - 1) }} />
              <Button icon={<RightOutlined />} onClick={() => { currentSlide < 3 && setCurrentSlide(currentSlide + 1) }} />
            </div>
          </div>
          <a href={slideDesc[currentSlide].img}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img style={{ boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px", borderRadius: 10 }} alt="" src={slideDesc[currentSlide].img} />
            </div>
          </a>
        </div>
      </div>
      <div style={{ padding: 40, maxWidth: 900, width: '100%', margin: 'auto', marginTop: 60 }}>
        <h1 style={{ color: "#003233", fontSize: secondaryHeaderSize }}>Move beyond extraction to insightful data analysis with <span style={{ fontSize: secondaryHeaderSize, color: '#00cdd1', fontWeight: 700 }}>'Custom Instruction'</span></h1>
          <a href="./instruction.png">
            <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
              <img style={{ boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px", borderRadius: 10 }} alt="" src="./instruction.png" />
            </div>
          </a>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 203.113 290.949"
            style={{ transform: "rotate(53deg)" }}
          >
            <g>
              <path fill="#003233" d="M108.521,195.245c-6.358-7.961-12.945-15.809-18.158-24.592-3.126-5.535-5.972-11.224-8.666-16.978C73.982,137.406,67.383,120.3,65.971,102.2A326.918,326.918,0,0,0,116.81,65.324a94.979,94.979,0,0,0,16.809-19.278c5.1-8.792,11.223-21.166,7.844-31.4-6.687-16.8-30.842-17.2-45.141-10.891a77.909,77.909,0,0,0-36.11,35.875C50.366,59.356,50,82.217,51.025,103.8,35.423,113.816,19.4,123.89,1.763,129.869A1.95,1.95,0,0,0,0,131.488c-.084,2.556,3.218,1.24,4.664.844a139.236,139.236,0,0,0,15.066-5.692A328.346,328.346,0,0,0,51.615,110.6c2.844,24.655,13.256,47.695,24.671,69.47,7.78,13.926,18.747,25.7,30.462,36.4a185.612,185.612,0,0,0,49.847,33.3C139.166,232.975,123.6,214.214,108.521,195.245ZM65.275,85.957c.073-16.761,1.932-34.236,11.278-48.6A64.656,64.656,0,0,1,97.536,17.63a31.08,31.08,0,0,1,24.168-2.5c5.041,1.618,9.11,5.585,7.527,11.221a59.557,59.557,0,0,1-4.473,12.771C114.139,59.051,84.114,81.04,65.412,94.136,65.308,91.412,65.252,88.688,65.275,85.957Z"/>
              <path fill="#003233" d="M202.86,280.177c-1.049-3.259-2.747-6.289-3.9-9.521-2.772-7.655-5.471-15.34-8.315-22.967a171.775,171.775,0,0,1-7.437-26.795c-2.262-11.393-4.9-22.864-6.54-34.392a1.46,1.46,0,0,0-.681-2.569c-3.75-.163-2.935,5.132-3.01,7.662.148,4.094.4,8.184.676,12.273.535,7.879,1.152,15.75,2.046,23.6,1.818,17.191,7.357,33.741,12.672,50.11-12.3-1.758-24.5-4.161-36.824-5.72-14.7-1.713-29.627-.705-44.336-1.3,14.537,6.186,30.24,8.644,45.344,13.031,11.968,3.363,24.15,6.456,36.594,7.293,4,.192,8.484.193,11.5-2.875A8.2,8.2,0,0,0,202.86,280.177Z"/>
            </g>
          </svg>
        </div>
        <a href="./analysis_output.png">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
            <img style={{ boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 6px 0px", borderRadius: 10 }} alt="" src="./analysis_output.png" />
          </div>
        </a>
      </div>
      <div  style={{ backgroundColor: '#003233', marginTop: 240}}>
        <div style={{ padding: '120px 40px', maxWidth: 900, width: '100%', margin: 'auto' }}>
            <h2 style={{ color: 'white' }}>
              Ready to simplify your data extraction?
            </h2>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
              <a href="https://app.extractninja.org/" style={{ width: '100%' }}>
                <Button style={{ width: '100%' }} size='large'>
                  <span style={{ fontWeight: 600 }}>Try now</span>
                </Button> 
              </a> 
              <a href="https://app.extractninja.org/pricing" style={{ width: '100%' }}>
                <Button type='primary' style={{ width: '100%', color: "#00cdd1" }} size='large'>
                  <span style={{ fontWeight: 600 }}>Pricing</span>
                </Button> 
              </a>
            </div>
          </div>
      </div>
      <div className='black-bg-transform' />
      <div className='black-bg-transform2' />
	  </main>
  );
}

export default Home;